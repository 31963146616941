import React from "react";
import ReactDOM from "react-dom";
import Preloader from "./components/Preloader/Preloader";
import Timer from "./components/Countdown/Timer";
import Optin from "./components/Optin/Optin";
import Redes from "./components/Redes/Redes";
import Logo from "./assets/img/logo.webp";

import "./styles.css";

function App() {
  return (
    <div className="App">
      <section className="p-relative">
        <div className="container content-header">
          <div className="logo-body">
            <img className="logo" src={Logo} alt="logo" />
          </div>
        </div>

        <div className="container content-body">
          <h1>Admin For Leagues</h1>
          <Timer />
          <Optin />
          <Redes />
          <Preloader />
        </div>
      </section>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
