import React, { Component } from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./Redes.css";

class Redes extends Component {
  modal() {
    const modal = document.getElementById("modal");
    modal.classList.toggle("is_open");
  }

  render() {
    return (
      <div className="redes">
        <div className="social_profiles">
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.facebook.com/profile.php?id=61554997746257"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook />
            </a>
          </div>
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.instagram.com/admin.for.leagues/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
          </div>
          <div className="SocialShare social_profile_item">
            <a
              href="https://www.tiktok.com/@admin.for.leagues?is_from_webapp=1&sender_device=pc"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok />
            </a>
          </div>
        </div>
        <p>
          <span>
            Copyright 2024 por Admin For Leagues, Inc. Todos los derechos
            reservados.
          </span>
        </p>
      </div>
    );
  }
}

export default Redes;
